/**
 * Display a media on the frontside of the card
 * Ask a question on the backside of the card
 */

// css
import "../css/Card.css";

// images
import star from "../assets/img/star.svg";

// components
import AudioPlayer from "./AudioPlayer";
import TextArea from "../components/TextArea";
// functions
import t from "../utils/translation";
import { getLanguage } from "../utils/storage";

const MediaTextAnswerCard = ({
  id,
  reward,
  image,
  media,
  isCardFlipped,
  isAudio = true,
  isPlaying,
  currentTime,
  totalTime,
  progress,
  answer,
  onAnswerChange,
  onPlay,
  onEnd,
  onReady,
  onProgress,
}) => {
  const language = getLanguage();

  // states
  const cardImage = require("../assets/cards/" + (image || id + ".png"));

  return (
    <div
      className={`flip-card no-floating media-text-card ${
        isCardFlipped ? "flip-card-flipped" : ""
      }`}
    >
      <div className="flip-card-container">
        <div
          className="flip-card-front"
          style={{ backgroundImage: `url(${cardImage})` }}
        >
          <div>
            <h3>{t("lastStep")}</h3>
            <hr />
          </div>
          <AudioPlayer
            src={require(`../assets/audio/${media || id}-${language}.m4a`)}
            isPlaying={isPlaying}
            currentTime={currentTime}
            totalTime={totalTime}
            progress={progress}
            onPlay={onPlay}
            onEnd={onEnd}
            onReady={(player, total) => onReady(id, player, total)}
            onProgress={onProgress}
          />
        </div>
        <div className="flip-card-back">
          <h3>{t("card" + id)}</h3>
          <hr />
          <TextArea
            placeholder={t("typeYourAnswer")}
            value={answer}
            onChange={onAnswerChange}
          />
          <div className="flip-card-score">
            <h4>{`${t("youWon")} ${reward} ${t("stars")}`}</h4>
            <img src={star} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaTextAnswerCard;
