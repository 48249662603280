import { ENDPOINT } from "../constants/constants";

const sendReport = async (data) => {
  try {
    const response = await fetch(ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    return {status : "success", response : json};
  }
  catch (error) {
    return {status : "error"};
  }
}

export { sendReport };
