const CARDS = [
  // ------------------- INTRO -------------------
  {
    id: 0,
    phase : "introduction",
    type : "info",
  },

  // ------------------- PHASE 1 -------------------
  {
    id: 1,
    phase: 1,
    type: "textAnswer",
  },
  {
    id: 2,
    phase: 1,
    type: "textAnswer",
  },
  {
    id: 3,
    phase: 1,
    type: "sliderAnswer",
  },
  {
    id: 4,
    phase: 1,
    type: "sliderAnswer",
  },
  {
    id: 5,
    phase: 1,
    type: "textAnswer",
  },
  {
    id: 6,
    phase: 1,
    type: "textAnswer",
  },
  {
    id: 7,
    phase: 1,
    type: "textAnswer",
  },
  {
    id: 8,
    phase: 1,
    type: "textAnswer",
  },
  {
    id: 9,
    phase: 1,
    type: "sliderAnswer100",
  },

  // ------------------- PHASE 2 -------------------
  {
    id: 10,
    phase: 2,
    type: "mediaAudio",
  },
  {
    id: 11,
    phase: 2,
    type: "mediaAudio",
  },
  {
    id: 12,
    phase: 2,
    type: "mediaAudio",
  },
  {
    id: 13,
    phase: 2,
    type: "mediaAudio",
  },
  {
    id: 14,
    phase: 2,
    type: "mediaAudio",
  },

  // ------------------- PHASE 3 -------------------
  {
    id: 15,
    phase: 3,
    type: "textAnswerInfo",
  },
  {
    id: 16,
    phase: 3,
    type: "textAnswerInfo",
  },
  {
    id: 17,
    phase: 3,
    type: "textAnswerInfo",
  },
  {
    id: 18,
    phase: 3,
    type: "textAnswerInfo",
  },
  {
    id: 19,
    phase: 3,
    type: "textAnswerInfo",
  },
  {
    id: 20,
    phase: 3,
    type: "textAnswerInfo",
  },

  // ------------------- PHASE 4 -------------------
  {
    id: 21,
    phase: 4,
    type: "mediaAudio",
  },
  {
    id: 22,
    phase: 4,
    type: "mediaAudio",
  },
  {
    id: 23,
    phase: 4,
    type: "mediaAudio",
  },
  {
    id: 24,
    phase: 4,
    type: "mediaAudio",
  },
  {
    id: 25,
    phase: 4,
    type: "mediaAudio",
  },

  // ------------------- PHASE 5 -------------------
  {
    id: 26,
    phase: 5,
    type: "mediaAudioTextAnswer",
  },

  // ------------------- PHASE 6 -------------------
  {
    id: 27,
    phase: 5,
    image : "0.png",
    type: "info",
  },

  // ------------------- REVIEW -------------------
  {
    id: 28,
    phase: "review",
    type: "sliderAnswer",
    image: "3.png",
  },
  {
    id: 29,
    phase: "review",
    type: "sliderAnswer",
    image: "4.png",
  },
  {
    id: 30,
    phase: "review",
    type: "sliderAnswer100",
    image: "9.png",
  },
  {
    id: 31,
    phase: "review",
    type: "review",
    image: "5.png",
  },
  {
    id: 32,
    phase: "review",
    image : "9.png",
    type : "infoLink"
  }
];

export { CARDS };
