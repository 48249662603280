
/**
 * It takes a number of seconds and returns a string in the format of `mm:ss` where `mm` is the number
 * of minutes and `ss` is the number of seconds.
 * @param second - The number of seconds to convert.
 * @returns string {mm:ss}
 */
const convertSecondToMinuteSecond = (second) => {
  const m = Math.floor(second % 3600 / 60).toString().padStart(2,'0');
  const s = Math.floor(second % 60).toString().padStart(2,'0');
  return `${m}:${s}`;
};

/**
 * Convert a decimal to a percent and rounding to two decimal places.
 * @param decimal - The decimal number you want to convert to a percentage.
 * @returns the result of the Math.round function.
 */
const convertDecimalToPercent = (decimal) => {
  return Math.round(decimal * 100 * 100) / 100;
}

export { convertSecondToMinuteSecond, convertDecimalToPercent };
