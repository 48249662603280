const EN = {

    // HOME
    homeTitle : "Able or not ? ",
    homeDescription : "Certificate in Educational Neuroscience with Cherine Fahim PhD",
    homeMention : "Translation by Nicole Estèbe and Shaylee Cotting",
    
    // MENU
    menuTitle: "Are you ready to set a course for confidence to learn better ?",
    pleaseEnterAValidName: "Enter a valid name",
    generalConditions : "By using the application, you accept the ",
    generalConditionsLink : "general conditions of participation. ",
    privacyPolicy : "The application saves your data so that you can resume your game from one day to the next and receive your and receive your report at the end of the game. The data can be viewed by the authors for internal and anonymous use only, in order to improve the application. the application. For more information, please consult our ",
    privacypPolicyLink : "privacy policy. ",
    iAcceptConditions: "I accept the general conditions of participation",
    readConditions: "Read the general conditions of participation",
    close: "Close",


    // INTRO
    card0 : "With 100 experience points, you will be able to open the treasure chest!",

    // PHASE 1
    card1 : "Choose a situation where you would like to feel more confident.",
    card2 : "What thought automatically comes to mind in relation to this situation ?",
    card3 : "From 0 to 10, how true does this thought seem to you ?",
    card4 : "From 0 to 10, how much is the situation currently stressing you ?",
    card5 : "Where do you feel the stress in your body ?",
    card6 : "What is your behavior as a result of this situation ?",
    card7 : "What are the consequences of this behavior ?",
    card8 : "What do you want instead of this situation ? What is your goal ?",
    card9 : "From 0 to 100%, at what level do you currently feel able to reach your goal ?",

    // PHASE 2
    card10 : "Alarm system", //X
    card11 : "Breathing challenge", //X
    card12 : "Wandering quarter-hour challenge", //X
    card13 : "Emotional Challenge", //X
    card14 : "Reconfiguration challenge", //X

    // PHASE 3
    card15 : "In this situation, is your thinking realistic ?",
    cardInfo15 : "We do not see things as they are, we interpret them according to our experiences.   ",
    card16 : "Is your thinking based on facts or feelings ?",
    cardInfo16 : "Often, in situations that provoke great emotions, the channels of communication with reason are confused.",
    card17 : "Is your thinking right ?",
    cardInfo17 : "There is evidence that the thought is not always true.",
    card18 : "Do you see the situation in black and white or are there possible nuances ?",
    cardInfo18 : "We often jump to conclusions based on emotion.",
    card19 : "What is the worst that can happen to you in this situation ?",
    cardInfo19 : "Even the worst case can be managed!",
    card20 : "What new interpretation could you give to your situation ?",
    cardInfo20 : "Your results do not measure your value.",

    // PHASE 4
    card21 : "The executive functions", //X
    card22 : "Attention", //X
    card23 : "Memorization", //X
    card24 : "Inhibition", //X
    card25 : "Planning", //X

    // PHASE 5
    card26 : "Choose an action",
    
    // PHASE 6
    card27 : "With 100 experience points, you will be able to open the treasure chest!",
    
    // REVIEW
    card28 : "From 0 to 10, how strong is your automatic thinking now ?",
    card29 : "From 0 to 10, how much is the situation still stressing you ?",
    card30 : "From 0 to 100%, at what point do you feel you are able to reach your goal ?",
    card32 : "Would you like to work further on you situation ? Find the best coaching for you at",

    // REVIEW
    congratulation: "Congratulations for your work!", //X
    encourage: "I encourage you to play again with other situations if this game has brought you something",  //X
    accumulate: "You have accumulated",  //X
    enterYourEmailAddress: "Enter your e-mail address",
    toReceiveYourGift: "To receive your report and a gift!",
    emailAddress: "E-mail address",  //X

    // REPORT
    reportTitle : "Here is your report/gift",
    yourReport : "Your report",
    yourGift : "Your gift",
    yourAnswers : "Your answers",
    cardNumber : "Card No.",
    cardsNumber : "Cards No.",
    question : "Question",
    questions : "Questions",
    answer : "Answer",
    comparison : "Comparison",
    difference : "Difference",

    // GENERAL
    applicationBy : "Application by",  //X
    by : "By",  //X
    startGame : "Start the game",  //X
    firstname : "First name",  //X
    lastname: "Name",  //X
    phase: "Phase",  //X
    next: "Next",  //X
    youWon: "you earn",  //X
    stars: "star(s)",  //X
    typeYourAnswer: "Enter your answer",  //X
    validateAnswer: "Validate the answer",  //X
    pleaseEnterAValidAnswer: "Enter a valid answer",  //X
    lastStep: "Last step",  //X
    end: "End",  //X
    introduction: "Introduction",  //X
    review: "Review",  //X
    yes : "Yes",  //X
    no : "No",  //
    confirmation : "Confirmation",  //X
    areYouSureToChoose0 : "Are you sure you to choose 0 as answer ?",  //X
    success : "Success", //X
    error : "Error", //X
    youAreGoingToBeRedirected : "You will be redirected to the home page by clicking on OK", //X
    anErrorOccurred : "An error has occurred", //X

}

export default EN;