/**
 * It checks if the user name is valid : https://stackoverflow.com/a/40056630/11365518
 * @param name - the name to validate.
 * @returns array
 */
const checkUserName = (name) => {
  return name.match(
    /^[\w'][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{3,}$/
  );
};

/**
 * It checks if the email is valid by checking if it matches the regex pattern.
 * @param email - The email address to validate.
 * @returns array
 */
const checkUserEmail = (email) => {
  return email.match(
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  );
};

/**
 * It checks if the answer is at least 3 characters long and if it contains any non-whitespace
 * characters.
 * @param answer - The answer the user has given.
 * @returns boolean - True if the email is valid, false otherwise.
 */
const checkAnswer = (answer) => {
  return answer.length >= 3 && answer.replace(/\s/g, "").length > 0;
};

export { checkUserName, checkUserEmail, checkAnswer };
