/**
 * Display an image on the frontside of the card
 * Ask a question on the backside of the card
 */

// css
import "../css/Card.css";

// images
import star from "../assets/img/star.svg";

// components
import TextArea from "../components/TextArea";

// functions
import t from "../utils/translation";

const TextAnswerCard = ({
  id,
  answer,
  reward,
  image,
  isCardFlipped,
  onCardFlip,
  onAnswerChange,
}) => {
  // states
  const cardImage = require("../assets/cards/"+(image || id + ".png"));
  return (
    <div className={`flip-card ${isCardFlipped ? "flip-card-flipped" : ""}`}>
      <div className="flip-card-container">
        <div
          className="flip-card-front"
          onClick={onCardFlip}
          style={{ backgroundImage: `url(${cardImage})` }}
        >
        </div>
        <div className="flip-card-back">
          <h3>{t("card"+id)}</h3>
          <hr />
          <TextArea
            placeholder={t("typeYourAnswer")}
            value={answer}
            onChange={onAnswerChange}
          />
          <div className="flip-card-score">
            <h4>{`${t("youWon")} ${reward} ${t("stars")}`}</h4>
            <img src={star} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAnswerCard;
