//css
import "../css/Confirm.css";

// components
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

const Confirm = () => {
  return (
      <ConfirmDialog/>
  );
};

const confirm = (
  header,
  message,
  acceptLabel,
  rejectLabel,
  hideReject,
  onAccept,
  onReject,
  onHide
) => {
  confirmDialog({
    header: header,
    message: message,
    icon: "pi pi-exclamation-triangle",
    acceptLabel: acceptLabel,
    rejectLabel: rejectLabel,
    accept: onAccept,
    reject: onReject,
    onHide: onHide,
    className: `confirm-dialog ${hideReject ? "confirm-dialog-hide-reject" : ""}`,
    acceptClassName: "confirm-dialog-accept-btn",
    rejectClassName: "confirm-dialog-reject-btn"
  });
};

export { Confirm, confirm };
