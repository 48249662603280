import { useState, useEffect } from "react";

// css
import "../css/Header.css";

// functions
import t from "../utils/translation";

// images
import star from "../assets/img/star.svg";

const Header = ({ phase, points }) => {
  const [updatingPoints, setUpdatingPoints] = useState(false);

  const title = isNaN(phase) ? t(phase) : t("phase") + " " + phase;

  //useEffect
  useEffect(() => {
    setUpdatingPoints(true);
    setTimeout(() => {
      setUpdatingPoints(false);
    }, 2000);
  }, [points]);

  return (
    <div className="header">
      <div className="header-left">
          <h2>
            {title}
          </h2>
      </div>
      <div className={`header-right ${updatingPoints ? "animate-score" : ""}`}>
        <h2>{points}</h2>
        <img src={star} alt="star" />
      </div>
    </div>
  );
};

export default Header;
