// react
import { React, useRef, useEffect } from "react";

// css
import "../css/AudioPlayer.css";

// components
import ReactPlayer from "react-player";

// images
import play from "../assets/img/play.svg";
import pause from "../assets/img/pause.svg";
import audioImg from "../assets/img/audio.svg";
const AudioPlayer = ({
  src,
  isPlaying = false,
  currentTime = 0,
  totalTime = 0,
  progress = 0,
  onPlay,
  onEnd,
  onReady,
  onProgress,
}) => {
  // refs
  const playerRef = useRef();

  return (
    <>
      <div className="audio-player-container">
        <div className="audio-player-controls" onClick={onPlay}>
          <img src={isPlaying ? pause : play}></img>
        </div>
        <div className="audio-player-sound-container">
          <input
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={(e) =>  playerRef.current.seekTo(e.target.value/100)}
            className="audio-player-slider"
          />
          {/**<span
              className="audio-player-marker"
              style={{ left: `${progress}%` }}
  ></span>*/}
          <div
            className="audio-player-overlay"
            style={{ width: `${progress}%` }}
          ></div>
          <img src={audioImg}></img>
        </div>
      </div>
      <div className="audio-player-time">
        <h4>
          {currentTime} / {totalTime}
        </h4>
      </div>
      <ReactPlayer
        url={src}
        ref={playerRef}
        playing={isPlaying}
        progressInterval={1000}
        onDuration={(duration) => onReady(playerRef.current, duration)}
        onEnded={onEnd}
        onProgress={onProgress}
      />
    </>
  );
};

export default AudioPlayer;
