// css
import "../css/Input.css";

const Input = ({ placeholder, value, onChange, disabled = false }) => {
  return (
    <input
      disabled={disabled}
      type="text"
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    >
      {value}
    </input>
  );
};

export default Input;
