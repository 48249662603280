const saveGameState = (game) => {
  localStorage.setItem("game", JSON.stringify(game));
};

const clearGameState = () => {
  localStorage.removeItem("game");
}

const getGameState = () => {
  return JSON.parse(localStorage.getItem("game"));
};

const saveUserName = (name) => {
  localStorage.setItem("name", name);
};

const getUserName = () => {
  return localStorage.getItem("name");
};

const getLanguage = () => {
  return localStorage.getItem("language") || "FR";
}

const saveLanguage = (language) => {
  localStorage.setItem("language", language);
}

const saveMediaProgress = (progress) => {
  localStorage.setItem("mediaProgress", JSON.stringify(progress));
}

const clearMediaProgress = () => {
  localStorage.removeItem("mediaProgress");
}

const getMediaProgress = () => {
  return JSON.parse(localStorage.getItem("mediaProgress"));
}

export { saveGameState, clearGameState, getGameState, saveUserName, getUserName, getLanguage, saveLanguage, saveMediaProgress, clearMediaProgress, getMediaProgress };
