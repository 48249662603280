// css
import "../css/Button.css";

// components
import { TailSpin  } from "react-loading-icons";

const Button = ({ text, onClick, loading = false, disabled = false }) => {
  return (
    <button
      disabled={loading || disabled}
      className={`button ${(loading || disabled) ? "button-disabled" : ""}`}
      onClick={onClick}
    >
      {text}
      {loading && <TailSpin  stroke="black" height={"3vh"}/>}
      
    </button>
  );
};

export default Button;
