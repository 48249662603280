import "../css/TextArea.css";

const TextArea = ({ placeholder, value, onChange }) => {
  return (
    <textarea
      className="textArea"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      rows="6"
    >
    </textarea>
  );
};

export default TextArea;
