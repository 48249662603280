/**
 * The last card of the game.
 */

// css
import "../css/Card.css";

// images
import star from "../assets/img/star.svg";

// components
import Input from "../components/Input";

// functions
import t from "../utils/translation";

const ReviewCard = ({
  id,
  answer,
  image,
  totalPoints,
  isCardFlipped,
  onCardFlip,
  onAnswerChange,
}) => {
  const cardImage = require("../assets/cards/background.png");
  return (
    <div className={`flip-card ${isCardFlipped ? "flip-card-flipped" : ""}`}>
      <div className="flip-card-container">
        <div
          className="flip-card-front flip-card-review"
          onClick={onCardFlip}
          style={{ backgroundImage: `url(${cardImage})` }}
        >
          <h3>{t("congratulation")}</h3>
          <h4 style={{ fontStyle: "italic" }}>{t("encourage")}</h4>
          <hr />
          <h3>{t("accumulate")}</h3>
          <div className="flip-card-review-score">
            <h1>{totalPoints}</h1>
            <img src={star} />
          </div>
        </div>
        <div className="flip-card-back flip-card-uppercase flip-card-review">

          <h3 style={{ marginBottom: "0.5vh" }}>
            {t("enterYourEmailAddress")}
          </h3>
          <h4 style={{ marginTop: "0.5vh" }}>{t("toReceiveYourGift")}</h4>
          <Input
            style={{ "z-index": "10" }}
            placeholder={t("emailAddress")}
            onChange={onAnswerChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
