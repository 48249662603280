const FR = {

    // HOME
    homeTitle : "Cap ou pas Cap ?",
    homeDescription : "CAS en neurosciences de l’éducation auprès de Dr Cherine Fahim",
    homeMention : "Traduction par Nicole Estèbe et Shaylee Cotting ",
    
    // MENU
    menuTitle : "Tu es prêt à mettre le cap sur la confiance pour mieux apprendre ?",
    pleaseEnterAValidName : "Entre un nom valide",
    generalConditions : "En utilisant l’application, tu acceptes les ",
    generalConditionsLink : "conditions générales de participation. ",
    privacyPolicy : "L’application enregistre tes données pour que tu puisses reprendre ta partie d’un jour à l’autre et recevoir ton bilan à la fin du jeu. Elles sont consultables par les auteurs uniquement, et ce exclusivement à usage interne et anonyme dans le but d’améliorer l’application. Pour plus d’infos, tu peux consulter notre ",
    privacypPolicyLink : "politique de confidentialité. ",
    iAcceptConditions : "J'accepte les conditions générales de participation",
    readConditions : "Lire les conditions générales de participation",
    close : "Fermer",
    // INTRO
    card0 : "Avec 100 points d’expérience, tu pourras ouvrir le coffre au trésor !",

    // PHASE 1
    card1 : "Choisis une situation où tu aimerais te sentir plus sûr-e de toi",
    card2 : "Quelle pensée te vient automatiquement à l’esprit par rapport à cette situation ?",
    card3 : "De 0 à 10, à combien cette pensée te semble-t-elle vraie ?",
    card4 : "De 0 à 10, à combien la situation te stresse-t-elle actuellement ?",
    card5 : "Où ressens-tu le stress dans ton corps ?",
    card6 : "Quel est ton comportement qui découle de cette situation ?",
    card7 : "Quels sont les conséquences de ce comportement ?",
    card8 : "Que veux-tu à la place de cette situation? Quel est ton but ?",
    card9 : "De 0 à 100%, à combien te sens-tu actuellement capable d’atteindre ton but ?",

    // PHASE 2
    card10 : "Système d’alarme",
    card11 : "Défi de respiration",
    card12 : "Défi du quart-heure vagabond",
    card13 : "Défi des émotions",
    card14 : "Défi de reconfiguration",

    // PHASE 3
    card15 : "Dans la situation qui t’occupe, ta pensée est-elle réaliste ?",
    cardInfo15 : "Nous ne voyons pas les choses telles qu’elles sont, nous les interprétons en fonction de nos expériences.",
    card16 : "Ta pensée est-elle fondée sur des faits ou sur des sentiments ?",
    cardInfo16 : "Souvent, dans les situations qui provoquent de grandes émotions, les voies de communication avec la raison sont brouillées.",
    card17 : "Ta pensée est-elle correcte ?",
    cardInfo17 : "Il existe des preuves que la pensée n’est pas toujours vraie.",
    card18 : "Vois-tu la situation en noir et blanc ou y aurait-il des nuances possibles?",
    cardInfo18 : "Souvent nous faisons des conclusions trop rapides sous l’effet de l’émotion.",
    card19 : "Dans la situation qui t’occupe, quel est le pire qui puisse t’arriver ?",
    cardInfo19 : "Même le pire des cas peut être gérable !",
    card20 : "Quelle interprétation nouvelle pourrais-tu donner à ta situation ?",
    cardInfo20 : "Tes résultats ne mesurent pas ta valeur.",

    // PHASE 4
    card21 : "Les fonctions exécutives",
    card22 : "L’attention",
    card23 : "La mémorisation",
    card24 : "L’inhibition",
    card25 : "La planification",

    // PHASE 5
    card26 : "Choisis une action",
    
    // PHASE 6
    card27 : "Avec 100 points d’expérience, tu pourras ouvrir le coffre au trésor !",
    
    // REVIEW
    card28 : "De 0 à 10, à combien ta pensée automatique est-elle encore forte maintenant ?",
    card29 : "De 0 à 10, à combien la situation te stresse-t-elle encore actuellement ?",
    card30 : "De 0 à 100%, à combien te sens-tu maintenant capable d’atteindre ton but ?",
    card32 : "Tu voudrais mieux débloquer ta situation ? Trouve l’accompagnement qui te correspond sur",

    // REVIEW
    congratulation : "Félicitations pour ton travail !",
    encourage : "Je t'encourage à rejouer avec d’autres situations si cette partie t’a apporté quelque chose",
    accumulate : "Tu as accumulé",
    enterYourEmailAddress : "Entre ton adresse e-mail",
    toReceiveYourGift : "Pour recevoir ton rapport ainsi qu’un cadeau !",
    emailAddress : "Adresse e-mail",

    // REPORT
    reportTitle : "Voici ton rapport/cadeau",
    yourReport : "Ton rapport",
    yourGift : "Ton cadeau",
    yourAnswers : "Vos réponses",
    cardNumber : "N° Carte",
    cardsNumber : "N° Cartes",
    question : "Question",
    questions : "Questions",
    answer : "Réponse",
    comparison : "Comparaison",
    difference : "Différence",


    // GENERAL
    applicationBy : "Application par",
    by : "Par",
    startGame : "Démarrer le jeu",
    firstname : "Prénom",
    lastname : "Nom",
    phase : "Phase",
    next : "Suivant",
    youWon : "tu gagnes",
    stars : "étoile(s)",
    typeYourAnswer : "Entre ta réponse",
    validateAnswer : "Valider la réponse",
    pleaseEnterAValidAnswer : "Entre une réponse valide",
    lastStep : "Dernière étape",
    end : "Fin",
    introduction : "Introduction",
    review : "Bilan",
    yes : "Oui",
    no : "Non",
    confirmation : "Confirmation",
    areYouSureToChoose0 : "Es-tu sûr de choisir 0 comme réponse ?",
    success : "Succès",
    error : "Erreur",
    youAreGoingToBeRedirected : "Tu vas être redirigé vers la page d'accueil en cliquant sur OK",
    anErrorOccurred : "Une erreur est survenue",
}

export default FR;