/**
 * Display an image on the frontside of the card
 * Ask a question on the backside of the card, can be answered with a slider
 */

// css
import "../css/Card.css";

// images
import star from "../assets/img/star.svg";

// components
import SliderInput from "../components/SliderInput";

// functions
import t from "../utils/translation";

const SliderAnswerCard = ({
  id,
  answer = 0,
  reward,
  image,
  maxSliderValue = 10,
  isCardFlipped,
  onCardFlip,
  onAnswerChange,
}) => {
  // states
  const cardImage = require("../assets/cards/" + (image || id + ".png"));
  return (
    <div className={`flip-card ${isCardFlipped ? "flip-card-flipped" : ""}`}>
      <div className="flip-card-container">
        <div
          className="flip-card-front"
          onClick={onCardFlip}
          style={{ backgroundImage: `url(${cardImage})` }}
        ></div>
        <div className="flip-card-back">
          <h3>{t("card"+id)}</h3>
          <hr />
          <SliderInput
            value={answer}
            onChange={onAnswerChange}
            min={0}
            max={maxSliderValue}
            className="slider-input"
          />
          <div className="flip-card-score">
            <h4>{`${t("youWon")} ${reward} ${t("stars")}`}</h4>
            <img src={star} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderAnswerCard;
